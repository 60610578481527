import axios from "axios"
import { ReactNode, useEffect, useState } from "react"
import { API_URL } from "../../const"
import { useAppContext } from "../../context/appContext"
import { Coupon, CouponProps } from "./components/Coupon"
import { ErrorMessage, Spinner } from "../../components"
import { useFormatMessage } from "../../hooks/useFormatMessage"

const WrapperComponent = ({ children }: { children: ReactNode }) => <div style={{ paddingTop: 100, paddingBottom: 80, paddingLeft: 0, paddingRight: 0 }}>{children}</div>

export const CouponsScreen = () => {
  const { token } = useAppContext();
  const { formatMessage } = useFormatMessage();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [coupons, setCoupons] = useState<Array<CouponProps> | undefined>(undefined)

  const getCoupons = () => {
    setIsLoading(true);
    axios.post(API_URL + '/coupons', { token }).then((res) => {
      if (res.data.status === 'success') {
        setCoupons(res.data.data)
      }

      if (res.data.status === 'error') {
        setError(res.data.message)
      }
    })
      .catch((e) => { console.error(e); setError(formatMessage('error.server-error')) })
      .finally(() => setIsLoading(false))
  }

  const onConfirm = (id: number) => {
    axios.post(API_URL + '/coupons/confirm', { token, coupon_id: id }).then((res) => {
      if (res.data.status === 'success') {
        getCoupons();
      }
    })
      .catch((e) => { console.error(e); setError(formatMessage('error.server-error')) })
  }

  useEffect(() => {
    getCoupons();
  }, [])

  if (isLoading) {
    return <WrapperComponent><Spinner /></WrapperComponent>
  }

  if (coupons !== undefined && coupons.length === 0) {
    return <WrapperComponent>{formatMessage('coupon.no-coupon')}</WrapperComponent>
  }

  return <WrapperComponent>

    <ErrorMessage message={error} />

    {coupons?.filter(({ isActive }) => isActive).sort((a, b) => a.isUsed === b.isUsed ? 0 : a.isUsed ? 1 : -1).map(({ image, bar_code, isActive, isUsed, id }, idx) => <Coupon key={idx} image={image} bar_code={bar_code} isActive={isActive} isUsed={isUsed} id={id} onConfirm={onConfirm} />)}

  </WrapperComponent>
}