import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { useAppContext } from "../../context/appContext";
import { useFormatMessage } from "../../hooks/useFormatMessage";

const INFO_MENU = <NavLink to="/info" className={({ isActive }) =>
  isActive ? "active-nav" : ""
}><i className="fa fa-info"></i><span><FormattedMessage id="footer.info" /></span></NavLink>

export const Footer = () => {
  const { token, logout } = useAppContext();
  const { formatMessage } = useFormatMessage();

  const onLogout = () => {
    if (window.confirm(formatMessage('footer.logout-confirm-modal')) && logout) {
      logout();
    }
  }

  if (!token) {
    return <div id="footer-bar" className="footer-bar-1">
      <NavLink to="login" className={({ isActive }) =>
        isActive ? "active-nav" : ""
      }><i className="fa fa-user"></i><span>
          <FormattedMessage id="footer.login" /></span></NavLink>
      <NavLink to="register" className={({ isActive }) =>
        isActive ? "active-nav" : ""
      }><i className="fa fa-user-plus"></i><span><FormattedMessage id="footer.register" /></span></NavLink>
      {INFO_MENU}
    </div>
  }


  return <div id="footer-bar" className="footer-bar-1">
    <NavLink to="/" className={({ isActive }) =>
      isActive ? "active-nav" : ""
    }><i className="fa fa-barcode"></i><span><FormattedMessage id="footer.coupons" /></span></NavLink>
    {INFO_MENU}
    <a href="#!" onClick={onLogout}><i className="fa fa-sign-out"></i><span><FormattedMessage id="footer.logout" /></span></a>
  </div>
}