import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthWrapper, ErrorMessage, Spinner } from "../../components";
import { API_URL } from "../../const";
import { useAppContext } from "../../context/appContext";
import { FormattedMessage } from "react-intl";
import { useFormatMessage } from "../../hooks/useFormatMessage";

export const RegisterScreen = () => {
  const navigate = useNavigate();
  const { formatMessage } = useFormatMessage();
  const { saveEmail, saveToken } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRe, setPasswordRe] = useState('');
  const areEmpty = email.trim().length === 0 || password.trim().length === 0 || passwordRe.trim().length === 0;

  const onSubmit = () => {
    setIsLoading(true);
    setError('');

    if (areEmpty) {
      setIsLoading(false);
      return setError(formatMessage('error.all-fields-required'));
    }

    if (password !== passwordRe) {
      setIsLoading(false);
      return setError(formatMessage('error.two-password-does-not-match'));
    }

    axios.post(API_URL + '/register', { email, password }).then((res) => {

      if (res.data.status === 'success') {
        saveEmail(res.data.user.email);
        saveToken(res.data.user.token);

        return navigate('/');
      }

      if (res.data.status === 'error') {
        setError(res.data.message)
      }

    })
      .catch((e) => { console.error(e); setError(formatMessage('error.server-error')) })
      .finally(() => setIsLoading(false))
  }

  return (
    <AuthWrapper>
      <h1 className="text-center font-800 font-40 mb-1 mt-4"><FormattedMessage id="auth.register" /></h1>
      <p className="color-highlight text-center font-12"><FormattedMessage id="auth.register-secondary" /></p>

      <div className="input-style has-icon">
        <i className="fa fa-user" style={{ left: 5 }}></i>
        <input type="email" className="form-control" id="email" placeholder={formatMessage('field.email')} value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
      </div>

      <div className="input-style has-icon mt-4">
        <i className="fa fa-lock" style={{ left: 5 }}></i>
        <input type="password" className="form-control" id="password" placeholder={formatMessage('field.password')} value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
      </div>

      <div className="input-style has-icon mt-4">
        <i className="fa fa-lock" style={{ left: 5 }}></i>
        <input type="password" className="form-control" id="passwordre" placeholder={formatMessage('field.password-re')} value={passwordRe} onChange={(e) => setPasswordRe(e.currentTarget.value)} />
      </div>

      {isLoading ?
        <Spinner /> :
        <button className="back-button btn btn-full btn-m shadow-large rounded-sm text-uppercase font-700 bg-highlight w-100" onClick={onSubmit}><FormattedMessage id="auth.register-button" /></button>}


      <ErrorMessage message={error} />
    </AuthWrapper>
  )
}