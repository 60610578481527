import {
  createContext,
  createElement,
  ReactNode,
  useContext,
  useState
} from 'react';

interface Context {
  email?: string;
  token?: string;
  saveEmail: (value: string) => void;
  saveToken: (value: string) => void;
  logout?: () => void;
}

const initialValue: Context = {
  email: localStorage.getItem('email') ?? undefined,
  token: localStorage.getItem('token') ?? undefined,
  saveEmail: () => null,
  saveToken: () => null
};

const AppContext = createContext<Context>(initialValue);

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState(initialValue.token)
  const [email, setEmail] = useState(initialValue.email)

  const saveEmail = (value: string) => {
    localStorage.setItem('email', value);
    return setEmail(value);
  }

  const saveToken = (value: string) => {
    localStorage.setItem('token', value);
    return setToken(value);
  }

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    setToken(undefined);
    setEmail(undefined);
  }

  return createElement(
    AppContext.Provider,
    { value: { email, token, saveEmail, saveToken, logout } },
    children
  );
};
