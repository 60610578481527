import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IntlProvider } from 'react-intl';

import { Footer, Header, ProtectedRoute, PublicRoute } from "./components";
import { CouponsScreen, LoginScreen, RegisterScreen, InfoScreen } from "./screens";
import { AppProvider } from "./context/appContext";

import locale from './locales/ro.json'

function App() {

  return (
    <IntlProvider locale="ro" messages={locale}>
      <div style={{ height: '100vh' }}>
        <AppProvider>
          <BrowserRouter>
            <Header />
            <Footer />
            <Routes>
              <Route path="/" element={<ProtectedRoute><CouponsScreen /></ProtectedRoute>} />
              <Route path="/info" element={<InfoScreen />} />
              <Route path="/login" element={<PublicRoute><LoginScreen /></PublicRoute>} />
              <Route path="/register" element={<PublicRoute><RegisterScreen /></PublicRoute>} />
            </Routes>
          </BrowserRouter>
        </AppProvider>
      </div>
    </IntlProvider>
  );
}

export default App;
